/* Styles for the Navbar container */
.navbar {
  display: flex;
  flex-direction: column;
  background-color: #333;
  color: white;
  height: 50vh;
  /* Full viewport height */
  width: 150px;
  /* Fixed width for the navbar */
  padding: 20px;
  /* Padding inside the navbar */
}

/* Title at the top of the Navbar */
.navbar-title {
  font-size: 1.5rem;
  margin-bottom: 20px;
  /* Space below the title */
}

/* Container for links */
.navbar-links {
  display: flex;
  flex-direction: column;
  /* Stack items vertically */
  gap: 15px;
  /* Space between links */
}

/* Individual link styles */
.nav-item {
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
}

.nav-item:hover {
  color: #61dafb;
  /* Highlight link on hover */
}

.nav-item.active {
  font-weight: bold;
  color: #61dafb;
  /* Highlight active link */
}

/* Logout button styling */
.logout-button {
  margin-top: auto;
  /* Pushes the button to the bottom */
  background: red;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.logout-button:hover {
  background: darkred;
  /* Darker red on hover */
}