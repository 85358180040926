.react-datetime-picker__wrapper {
  border: none !important;
  padding: 0.5rem;
}

.react-datetime-picker__inputGroup__divider,
.react-datetime-picker__inputGroup__leadingZero {
  display: inline-block;
  opacity: 1 !important;
}

.react-datetime-picker__button {
  padding: 4px;
}

.react-datetime-picker__calendar {
  width: auto !important;
}